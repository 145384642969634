import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import IconButtonPlay from 'shared/Linearthinking/icons/IconButtonPlay';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import VideoComputerIcon from 'components/Icon/SVGIcons/VideoComputer';
import { LazyImage } from 'components/LazyImage';
import { imageCoverStyle } from 'style/css-style';

const VideoPlayer = lazy(() => import('plyr-react'));

const captionPositionMap = {
  bottom: 'column',
  top: 'column-reverse'
};

const captionAlignMap = {
  left: 'start',
  center: 'center',
  right: 'end'
};

const Wrapper = styled.figure`
  padding: 0;
  width: 100%;
  max-width: ${({ $width }) => `${$width}px`};
  margin: ${({ $center }) => ($center ? '0 auto' : '0')};
  display: flex;
  flex-direction: ${({ $captionPosition }) =>
    captionPositionMap[$captionPosition]};
  align-items: ${({ $captionAlign }) => captionAlignMap[$captionAlign]};
  /**
   * flex-gap on safari is now supported in Safari 14.1 (Sep 2021)
   * https://caniuse.com/flexbox-gap
   */
  gap: 16px;
  flex-shrink: 0;

  .plyr {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    min-width: 0;
  }

  .plyr__control--overlaid {
    background-color: ${colorsV2.blackTransparent40};
    backdrop-filter: blur(10px);

    &:hover {
      background-color: ${colorsV2.blackTransparent70};
    }
  }
`;

const Poster = styled(LazyImage)`
  img {
    ${imageCoverStyle('cover')}
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: ${({ $iconSize }) => `${$iconSize}px`};
  height: ${({ $iconSize }) => `${$iconSize}px`};
  backdrop-filter: blur(10px);
  font-size: 23px;
  padding: 0;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  background-color: ${colorsV2.blackTransparent40};

  svg {
    transform: translateX(2px); /* Make play icon looks more centered */
  }
`;

const VideoWrapper = styled.button`
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
  position: relative;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &:hover {
    ${PlayButton} {
      background-color: ${colorsV2.blackTransparent70};
    }
  }

  &:active {
    ${PlayButton} {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
`;

const CaptionWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VideoThumbnail = ({
  poster = '',
  posterHandle = '',
  caption = '',
  aspectRatio = '16/9',
  width = 712,
  iconSize = 48,
  onClick = () => {},
  playInline = false,
  plyrSource = {},
  plyrOptions = {},
  center = false,
  captionIcon = null,
  captionPosition = 'bottom',
  captionAlign = 'left'
}) => {
  const isSSR = typeof window === 'undefined';
  return (
    <Wrapper
      className="video-thumbnail"
      $width={width}
      $center={center}
      $captionPosition={captionPosition}
      $captionAlign={captionAlign}
    >
      {playInline ? (
        <>
          {!isSSR && (
            <Suspense fallback={<div>Loading...</div>}>
              <VideoPlayer
                source={plyrSource}
                options={{
                  ratio: aspectRatio.replace('/', ':'),
                  ...plyrOptions
                }}
              />
            </Suspense>
          )}
        </>
      ) : (
        <VideoWrapper $aspectRatio={aspectRatio} onClick={onClick}>
          <Poster
            src={poster}
            handle={posterHandle}
            alt={caption}
            loading="lazy"
            width={width}
            height={width / aspectRatio}
          />
          <PlayButton
            $iconSize={iconSize}
            title="Play video"
            aria-label="Play video"
          >
            <IconButtonPlay size={iconSize * 0.6} />
          </PlayButton>
        </VideoWrapper>
      )}

      {caption && (
        <CaptionWrapper>
          <IconWrapper>{captionIcon || <VideoComputerIcon />}</IconWrapper>
          <Typography variant="regular/18-24" color={colorsV2.gray120}>
            <figcaption>{caption}</figcaption>
          </Typography>
        </CaptionWrapper>
      )}
    </Wrapper>
  );
};
