import Spinner from '@dolstaff/shared/es/Spinner';
import { Typography } from 'components/DesignSystemV2/Typography';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  color: ${colorsV2.neutral180};
  background-color: ${colorsV2.white100};

  & .path {
    stroke: currentColor;
  }
`;

const OutlinedButton = ({ children, loading = false, ...props }) => {
  return (
    <StyledButton {...props}>
      {loading && <Spinner size={24} color="currentColor" />}
      <Typography variant="semi-bold/16-24">{children}</Typography>
    </StyledButton>
  );
};

export default OutlinedButton;
